import './index.scss'
const ShadowWords = () => {
    return (
        <div className='flex items-center justify-center h-screen' id='shadowWordsContainer'>
        <h1 id='shadowWords'>
            JAMES
        </h1>
        </div>
    )
}
export default ShadowWords